// ag-grid
@use 'ag-grid-enterprise/styles/ag-grid.css' as agGridEnterprise;
@use 'ag-grid-enterprise/styles/ag-theme-balham.css';

@use '@ng-select/ng-select/themes/default.theme.css';
@use 'bootstrap/scss/bootstrap';

// jabil Built-in Styles
@use 'foundation/styles/button';
@use 'foundation/styles/container';
@use 'foundation/styles/input';
@use 'foundation/styles/typography';
@use 'foundation/styles/resets';

@use 'core/styles/ag-grid' as coreAgGrid;
@use 'core/styles/ngb';
@use 'core/styles/dropdown';
@use 'core/styles/keyframes';
@use 'core/styles/misc';
@use 'core/styles/modal';
@use 'core/styles/scroll';
@use 'core/styles/toast';

// internal styles
@use 'mi-ag-grid';
@use 'overrides';
@use 'badges';
@use 'accordion';

.cursor-pointer {
	cursor: pointer;
}

.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.badge {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px 12px;
	font-size: 10px;
	font-weight: 700;
	text-transform: uppercase;
	border-radius: 50px;
	color: var(--cool-gray-40, #646464);
	background: var(--cool-gray-00, #f5f5f5);
}

.required {
	color: var(--red-60, #e25141);
}

.invalid {
	border: 1px solid var(--red-60, #e25141);
}

.error-message {
	color: var(--red-60, #e25141);
	font-size: 12px;
}
