@use 'foundation/styles/variables';
@use 'foundation/styles/flex';

.mi-ag-grid {
	flex: 1;
	min-height: 30em;
	// height: 100%;
	position: relative;
	font-variant-numeric: lining-nums tabular-nums;

	.ag-root-wrapper {
		position: absolute;
		height: 100%;
		width: 100%;
	}
}

.mi-custom-ag-grid-overlay {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	padding: 12px;
	border-radius: 4px;
	font-weight: 600;
	color: var(--yellow-80);
	border: 1px solid var(--yellow-60);
	background-color: var(--yellow-10);
	opacity: 0;
	transform-origin: top center;
	animation: rotateX 300ms 60ms ease-in-out forwards;
}

.ag-checkbox-input {
	&:focus {
		outline: none;
		box-shadow: 0 0 3px rgb(0 0 0 / 25%);
	}

	&:checked:focus,
	&:checked:focus {
		box-shadow: none;
	}

	&:disabled {
		background-color: var(--cool-gray-10);
	}
}

.ag-checkbox-input {
	opacity: 1 !important;
	width: 18px !important;
	height: 18px !important;
	appearance: none;
	border: 1px solid var(--cool-gray-10);
	background-color: var(--static-white);
}

.ag-checkbox-input {
	position: relative;

	&:checked:before,
	&:checked:after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
	}

	&:checked:before {
		background-color: var(--blue-90);
	}

	&:checked:after {
		position: absolute;
		top: -1px;
		left: -1px;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='18px' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
		background-repeat: no-repeat;
	}

	&:checked:focus,
	&:focus {
		outline: none !important;
	}
}

.ag-checkbox-input-wrapper {
	box-shadow: none !important;
	position: relative;
	top: 2px;

	&::after {
		content: none !important;
	}
}

.ag-header-cell {
	.ag-checkbox-input-wrapper {
		top: -1px;
	}
}

.ag-checkbox-input-wrapper.ag-indeterminate {
	&::after {
		content: '' !important;
		position: absolute;
		top: 1px;
		left: 1px;
		height: 100%;
		width: 100%;
		background: var(--blue-90);
		background-position: center;
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24'%3e%3cpath fill='%23fff' d='M0 10h24v4h-24z'/%3e%3c/svg%3e");
	}
}

.ag-row-pinned {
	background: var(--blue-10);
}

.ag-header-group-text,
.ag-uppercase-header {
	text-transform: uppercase;
	font-weight: 700;
}
