@use 'foundation/styles/flex';
@use 'foundation/styles/variables';

* {
	box-sizing: border-box;
}

body {
	overflow-x: hidden;
	margin: 0;
}

jbl-not-found,
jbl-not-permitted {
	height: 100vh;
	display: block;
}

.jbl-pointer {
	cursor: pointer;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
	top: inherit !important;
}

.modal-confirmation__action-secondary.jbl-btn.jbl-btn-sm.jbl-btn-secondary {
	min-width: 64px !important;
	margin-left: 30px;
}

/*
	Limit subtile height in JblModalConfirmationComponent
	which contains missing JPNs, strictly bound to missing-jpns class
*/

.mi-attr-check-badge {
	padding: 4px 8px;
	border-radius: 80px;
	background-color: gray;
	color: rgba(255, 255, 255, 0.95);
	font-weight: bold;

	&.success {
		background-color: #8bc34a;
	}

	&.fail {
		background-color: #f44336;
	}
}

jbl-header header.header {
	z-index: 10;
}

ng-dropdown-panel.ng-dropdown-panel {
	z-index: 2;
}

.pn-cell-search-dropdown {
	padding: 0;
	margin-top: -1px;
	margin-left: 0px;
	border-radius: 0;
	max-height: 200px;
	overflow-y: auto;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	text-align: center;
}

.form-section .input-select-title {
	display: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}

.should-cost-analysis.box,
.digital-factory-estimates.box,
.suggested-suppliers.box {
	.tile {
		position: relative;
	}

	.tile-body {
		padding: 0;
	}
}

.parts-table .tile-body {
	height: 100% !important;
}

.part-attributes {
	.tile {
		position: relative;
	}
}

.tile .tile-header h3 {
	font-size: 12px;
}

.suggested-suppliers .tabs {
	align-items: flex-end;
}

.cost-drivers-sidepanel {
	width: 800px !important;
}

.dfe-checklist-sidepanel {
	width: 770px !important;
}

.ag-header-cell-required .ag-header-cell-text::after {
	content: '*';
	color: red;
	display: inline-block;
	margin-left: 2px;
}

mi-chips {
	width: 400px;
}

.header-nav__logo .jbl-logo-content__img {
	height: 34px !important;
	margin-bottom: 6px;
}

.tile-tooltip .tooltip-inner {
	background: white !important;
	color: #646464;
	text-align: left;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	width: 276px;

	.title {
		margin-top: 0;
		margin-bottom: 6px;
		font-size: 12px;
		font-weight: 700;
	}

	.text {
		font-size: 12px;
		font-weight: 400;
		margin: 0 0 10px 0;
	}
}

.tile-tooltip .tooltip-arrow::before {
	border-top-color: white !important;
}

.tabs {
	display: flex;
}

.suppliers-tabs {
	.tabs li:nth-child(1), // suggested tab
	.tabs li:nth-child(3) {
		// quote win tab
		flex: 3;
	}

	.tabs li:nth-child(2) {
		// digital factories tab
		flex: 4;

		& > .tabs-item__title {
			justify-content: center;
		}
	}
}

.tabs-item__title {
	justify-content: center;
}

.qw-modal .modal-dialog {
	width: 500px;
}

.dupl-modal .modal-dialog {
	width: 525px;
}

.jbl-checkbox:indeterminate {
	background-color: var(--blue-90);
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
}

.task-line-break {
	line-break: anywhere;
}